/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import uniqid from 'uniqid'
import parse from 'html-react-parser'
import SingleArticleTeaser from '~components/globals/SingleArticleTeaser'
import * as styles from './style.module.scss'

const InterviewTeaser = ({ interviews, interviewTeaserData, type }) => {
  const content = interviewTeaserData.data

  return (
    <section className={styles.container}>
      <div className={styles.headline}>
        {parse(content.headline.html)}
      </div>
      {/* <h4 className={styles.subline}>{parse(content.subline.html)}</h4> */}
      <div className={styles.teaserContainer}>
        {interviews.map(item => (
          <SingleArticleTeaser
            data={item.data}
            key={uniqid()}
            uid={item.uid}
            type={type}
          />
        ))}
      </div>
      <Link to={`/${type}`} className={styles.cta}>
        {parse(content.cta.html)}
      </Link>
    </section>
  )
}

export default InterviewTeaser

InterviewTeaser.propTypes = {
  interviewTeaserData: PropTypes.shape({
    data: PropTypes.shape({
      headline: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
      ),
      subline: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
      ),
      cta_text: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        }),
      ),
    }),
  }).isRequired,
  interviews: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        slugs: PropTypes.arrayOf(PropTypes.string.isRequired),
        data: PropTypes.shape({
          headline: PropTypes.shape({
            text: PropTypes.string.isRequired,
          }),

          image: PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired,
          }),
        }),
      }),
    ),
  }).isRequired,
}
