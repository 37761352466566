// extracted by mini-css-extract-plugin
export var eyebrow = "style-module--eyebrow--1Bi3N";
export var ctBlack = "style-module--ctBlack--2854e";
export var ctSmall = "style-module--ctSmall--1QFtO";
export var ctMedium = "style-module--ctMedium--OBVVD";
export var ctLarge = "style-module--ctLarge--1eeae";
export var ctXLarge = "style-module--ctXLarge--1lgrd";
export var ctIntro = "style-module--ctIntro--3QlCj";
export var ctBody = "style-module--ctBody--3r4IY";
export var ctBodyMedium = "style-module--ctBodyMedium--3mToe";
export var ctBodyBold = "style-module--ctBodyBold--1dWKz";
export var ctLink = "style-module--ctLink--1dH6c";
export var link = "style-module--link--19lTa";
export var frutiLarge = "style-module--frutiLarge--rHCvj";
export var ctUi = "style-module--ctUi--XNd0y";
export var ctMeta = "style-module--ctMeta--1VUTu";
export var ctMicro = "style-module--ctMicro--PSUjF";
export var ctMediumItalic = "style-module--ctMediumItalic--3U-QH";
export var FeaturedArea = "style-module--FeaturedArea--1KDxt";
export var grid = "style-module--grid--gN9cv";