// extracted by mini-css-extract-plugin
export var eyebrow = "style-module--eyebrow--FZls-";
export var ctBlack = "style-module--ctBlack--1dKmf";
export var ctSmall = "style-module--ctSmall--3H0y-";
export var ctMedium = "style-module--ctMedium--2JNJi";
export var ctLarge = "style-module--ctLarge--3WK8r";
export var headline = "style-module--headline--1vUKS";
export var ctXLarge = "style-module--ctXLarge--1Lsaj";
export var ctIntro = "style-module--ctIntro--7DMln";
export var ctBody = "style-module--ctBody--3TuGZ";
export var ctBodyMedium = "style-module--ctBodyMedium--1h-w9";
export var ctBodyBold = "style-module--ctBodyBold--3yz62";
export var ctLink = "style-module--ctLink--dGNP2";
export var cta = "style-module--cta--3oQpn";
export var frutiLarge = "style-module--frutiLarge--1rU0E";
export var ctUi = "style-module--ctUi--1h_m_";
export var subline = "style-module--subline--3a_p7";
export var ctMeta = "style-module--ctMeta--2htPi";
export var ctMicro = "style-module--ctMicro--2NBY2";
export var ctMediumItalic = "style-module--ctMediumItalic--1mOZE";
export var container = "style-module--container--3y-_n";
export var teaserContainer = "style-module--teaserContainer--2O7ig";