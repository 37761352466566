// extracted by mini-css-extract-plugin
export var eyebrow = "style-module--eyebrow--2lj1P";
export var ctBlack = "style-module--ctBlack--2KvUK";
export var ctSmall = "style-module--ctSmall--3LiOU";
export var ctMedium = "style-module--ctMedium--1j07p";
export var ctLarge = "style-module--ctLarge--M6cik";
export var title = "style-module--title--ekrl4";
export var ctXLarge = "style-module--ctXLarge--w_Yz6";
export var ctIntro = "style-module--ctIntro--2-kqY";
export var description = "style-module--description--ONYSf";
export var ctBody = "style-module--ctBody--13Tqf";
export var ctBodyMedium = "style-module--ctBodyMedium--Kzxf6";
export var ctBodyBold = "style-module--ctBodyBold---_PjH";
export var ctLink = "style-module--ctLink--1EtIH";
export var frutiLarge = "style-module--frutiLarge--1ayEt";
export var ctUi = "style-module--ctUi--2D_fQ";
export var ctMeta = "style-module--ctMeta--cvthU";
export var ctMicro = "style-module--ctMicro--1lEet";
export var ctMediumItalic = "style-module--ctMediumItalic--1dAaI";
export var Reflections = "style-module--Reflections--2vk0B";
export var imageContainer = "style-module--imageContainer--Ua3qa";
export var list = "style-module--list--218ST";
export var presentation = "style-module--presentation--2t1i8";
export var note = "style-module--note--2BbgO";