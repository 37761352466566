/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import * as styles from './style.module.scss'

const ArticleHighlight = ({ data, uid }) => (
  <div className={styles.ArticleHighlight}>
    <Link to={`/article/${data.type}/${uid}`} className={styles.link}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${data.image.url})` }}
      />
    </Link>
    <div className={styles.content}>
      {data.themes && (
        <div className={styles.themes}>
          {data.themes.map(theme => (
            <h3 className={styles.theme}>{theme.theme}</h3>
          ))}
        </div>
      )}
      <Link to={`/article/${data.type}/${uid}`}>
        <h4 className={styles.title}>
          {data.headline.text}
        </h4>
      </Link>
      <p className={styles.description}>
        {parse(data.intro_text.html)}
      </p>
      <Link to={`/article/${data.type}/${uid}`} className={styles.cta}>
        Read more
      </Link>
    </div>
  </div>
)

export default ArticleHighlight

ArticleHighlight.propTypes = {
  data: PropTypes.shape({
    headline: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
    ),
    intro_text: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
    themes: PropTypes.arrayOf(
      PropTypes.shape({
        theme: PropTypes.string.isRequired,
      }),
    ),
    topic_list: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
    ),
    type: PropTypes.string.isRequired,
    mailchimp_archive_link: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
  uid: PropTypes.string.isRequired,
}
