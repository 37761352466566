/* eslint-disable react/prop-types */
import React from 'react'
import uniqid from 'uniqid'
import { Link } from 'gatsby'
import ArticleHighlight from '~components/ArticleHighlight'
import * as styles from './style.module.scss'
import SingleArticleTeaser from '~components/globals/SingleArticleTeaser'
import Container from '~components/globals/Container'

const FeaturedArea = ({ cta, items }) => (
  <div className={styles.FeaturedArea}>
    <ArticleHighlight data={items[0][0].data} uid={items[0][0].uid} />
    <Container extraClassName={styles.container}>
      <div className={styles.grid}>
        {items.slice(1).map(item => (
          <SingleArticleTeaser
            data={item[0].data}
            key={uniqid()}
            uid={item[0].uid}
          />
        ))}
      </div>
      <Link to="/insights" className={styles.link}>
        {cta}
      </Link>
    </Container>
  </div>
)

export default FeaturedArea
