import * as React from 'react'
import { head } from 'lodash-es'
import { graphql } from 'gatsby'
import uniqid from 'uniqid'
import Container from '~components/globals/Container'
import FeaturedArea from '~components/FeaturedArea'
import SubscriptionBanner from '~components/SubscriptionBanner'
import Reflections from '~components/Reflections'
import InterviewTeaser from '~components/teasers/InterviewTeaser'
import Seo from '~components/globals/Seo'

const Homepage = ({
  data: {
    allPrismicPage,
    allPrismicArticle,
    allPrismicHomepage,
    prismicFeaturedArea,
    prismicInfographicTeaser,
    prismicInterviewTeaser,
  },
  location,
}) => {
  const { sections } = head(allPrismicHomepage.nodes).data

  // FEATURED SECTION
  const featuredAreaItems = prismicFeaturedArea.data.articles
  const featuredArticles = featuredAreaItems.map(({ article }) =>
  // eslint-disable-next-line implicit-arrow-linebreak
    allPrismicArticle.nodes.filter(item => item.prismicId === article.id),
  )

  // INFOGRAPHIC SECTION
  const infographics = allPrismicArticle.nodes.filter(item => item.data.type === 'infographic')

  // INTERVIEWS SECTION
  const interviews = allPrismicArticle.nodes.filter(item => item.data.type === 'interview')

  const { sharing_description, sharing_image, sharing_title } = allPrismicPage.edges[0].node.data

  return (
    <>
      <Seo
        title={sharing_title.text}
        description={sharing_description.text}
        url={location.href}
        image={sharing_image.url}
      />
      {sections.map(({ section }) => {
        let sectionEl
        switch (section.type) {
          case 'featured_area':
            sectionEl = (
              <FeaturedArea
                cta={prismicFeaturedArea.data.cta}
                items={featuredArticles}
                key={uniqid()}
              />
            )
            break
          default:
            sectionEl = null
        }
        return sectionEl
      })}
      <Container extraClassName="homeContainer">
        <SubscriptionBanner key={uniqid()} />
        <InterviewTeaser
          interviews={infographics.slice(0, 6)}
          interviewTeaserData={prismicInfographicTeaser}
          key={uniqid()}
          type="infographics"
        />
        <Reflections />
        <InterviewTeaser
          interviews={interviews.slice(0, 6)}
          interviewTeaserData={prismicInterviewTeaser}
          key={uniqid()}
          type="interviews"
        />



        {/* {sections.map(({ section }) => {
          let sectionEl
          switch (section.type) {
            case 'subscription_banner':
              sectionEl = <SubscriptionBanner key={uniqid()} />
              break
            case 'news_ticker_teaser':
              sectionEl = (
                null
              )
              break
            case 'infographic_teaser':
              sectionEl = (
                <InfographicTeaser
                  infographicTeaserData={prismicInfographicTeaser}
                  infographicItemsData={selectedInfographics}
                  key={uniqid()}
                />
              )
              break
            case 'startup_list_teaser':
              sectionEl = null
              break
            case 'funding_teaser':
              sectionEl = null
              break
            case 'interview_teaser':
              sectionEl = (
                <InterviewTeaser
                  interviewItemsData={allPrismicInterview}
                  interviewTeaserData={prismicInterviewTeaser}
                  key={uniqid()}
                />
              )
              break
            default:
              sectionEl = null
          }
          return sectionEl
        })} */}
      </Container>
    </>
  )
}

export default Homepage

export const pageQuery = graphql`
  query homepageQuery {
    allPrismicHomepage {
      nodes {
        data {
          sections {
            section {
              type
            }
          }
        }
      }
    }
    allPrismicPage(filter: {data: {slug: {eq: "home"}}}) {
      edges {
        node {
          data {
            sharing_description {
              text
            }
            sharing_title {
              text
            }
            sharing_image {
              url
            }
          }
        }
      }
    }
    allPrismicArticle(sort: {fields: data___date, order: DESC}) {
      nodes {
        prismicId
        data {
          image {
            alt
            url
          }
          image_preview {
            alt
            url
          }
          intro_text {
            html
          }
          headline {
            text
          }
          teaser_label
          type
        }
        uid
      }
    }
    prismicFeaturedArea {
      data {
        articles {
          article {
            id
          }
        }
        cta
      }
    }
    prismicInterviewTeaser {
      data {
        headline {
          html
        }
        subline {
          html
        }
        cta {
          html
        }
      }
    }
    prismicInfographicTeaser {
      data {
        headline {
          html
        }
        cta {
          html
        }
        subline {
          html
        }
        infographics {
          infographic {
            id
          }
        }
      }
    }
  }
`