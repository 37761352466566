/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import uniqid from 'uniqid'
import parse from 'html-react-parser'
import * as styles from './style.module.scss'

const Reflections = () => {
  const { allPrismicArticle, prismicReflections } = useStaticQuery(graphql`
    {
      prismicReflections {
        data {
          cta
          headline
          image {
            alt
            url
          }
          description {
            html
          }
          notes {
            ceo_note {
              id
              uid
            }
          }
        }
      }
      allPrismicArticle(filter: {data: {type: {eq: "ceo"}}}) {
        edges {
          node {
            id
            prismicId
            data {
              note_teaser {
                html
              }
            }
            uid
          }
        }
      }
    }
  `)

  const { data } = prismicReflections
  const selectedNotes = data.notes.map(({ ceo_note }) =>
    allPrismicArticle.edges.filter(item => item.node.prismicId === ceo_note.id))

  return (
    <div className={styles.Reflections}>
      <div className={styles.list}>
        <p className={styles.title}>{data.headline}</p>
        <>
          {selectedNotes.map(note => (
            <div key={uniqid()} className={styles.note}>
              <Link to={`/article/ceo/${note[0].node.uid}`}>
                {parse(note[0].node.data.note_teaser.html)}
              </Link>
              <Link to={`/article/ceo/${note[0].node.uid}`}>
                Read more
              </Link>
            </div>
          ))}
        </>
      </div>
      <div className={styles.presentation}>
        <div className={styles.imageContainer}>
          <img src={data.image.url} alt={data.image.alt} />
        </div>
        <div data-html className={styles.description}>
          {parse(data.description.html)}
        </div>
      </div>
    </div>
  )
}

export default Reflections
